import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Check if the protocol is not HTTPS
if (window.location.protocol !== 'https:' && process.env.NODE_ENV === 'production') {
  // Redirect to the HTTPS version
  window.location.href = 'https://' + window.location.host + window.location.pathname;
} else {
  // Continue with rendering your React app
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
