import React, { useEffect, useState } from 'react';
import './ComingSoon.css';
import { API_URL } from '../../Config';
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'

const ComingSoon = () => {
  const { width, height } = useWindowSize()
  const [effect, setEffect] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    document.title = 'Init Technology'; // Set the desired title here
    return () => {
      // Optionally, you can reset the title when the component is unmounted
      document.title = 'Default Title';
    };
  }, []); 

  useEffect(() => {
    document.title = 'Init Technology'; // Set the desired title here
    return () => {
      // Optionally, you can reset the title when the component is unmounted
      document.title = 'Default Title';
    };
  }, []); 

  const [formData, setFormData] = useState({
    email: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleEmail = async () => {
    try {
      if (!isValidEmail(formData.email)) {
        alert('Please enter a valid email address');
        return;
      }

      const response = await fetch(API_URL + '/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        setEffect(true);
        alert(`You're officially subscribed! Enjoy being a part of our VIP circle.`);
        setFormData({ email: '' });
      } else if(response.status === 409){
        setEffect(false);
        alert('Email already exists');
      } else {
        setEffect(false);
        alert('Error inserting email');
      }
    } catch (error) {
      console.error(error);
    }
  };

return(
<div className='body'> 
  <div className="flex-container">
    <div className="flex-item-left">
      <video className='backGroundVideo' muted loop autoPlay playsInline controls={false}>
        <source src="../assets/logo_bg.mp4" alt="linkedin-icon" type="video/mp4" />
      </video>
    </div>
    <div className="flex-item-right">
      {effect && (<Confetti
      width={width}
      height={height}
    />)}
    
      <p className='text1'><span className='init'>INIT</span> for the long run</p> 

      {effect ?  <p className='getNotified'>
        <b>Congratulations! <br/> You'll get notified when we'll be live.&nbsp;</b>
      </p> : <p className='getNotified'>
        <b>Get notified when we get live!&nbsp;</b>
      </p> }
      
      <input disabled = {effect} 
      type='email'
      className='email_input' 
      placeholder={isFocused ? 'Enter your email here' : 'Email'}
      name="email" 
      onChange={handleInputChange} 
      value={formData.email}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}/><br />

      {effect ? <button disabled={effect} className='subscribed_Btn' type='submit'>Subscribed!</button> : <button className='subscribe_Btn' type='submit' onClick={handleEmail} >Subscribe</button> }<br />
      
      <p className='text3'><b>Website Under Construction</b></p>
      <div className='icons'>

      <a href='https://wa.me/+919664570369?text=Hi%20Init%20Technology,%20I`m%20on%20the%20lookout%20for%20innovative%20solutions,%20and%20your%20services%20caught%20my%20eye.%20Can%20we%20chat%20about%20it%20?' target='_blank' rel='noopener noreferrer'>
        <img src="../assets/whatsapp.png" alt="facebook-icon" height={20}/>
      </a>&nbsp;&nbsp;&nbsp;&nbsp;

      <a href='tel: +919664570369' target='_blank' rel='noopener noreferrer'>
          <img src="../assets/phone.png" alt="instagram-icon" height={20}/>
        </a>&nbsp;&nbsp;&nbsp;&nbsp;

        <a href='mailto:contact@inittechnology.co' target='_blank' rel='noopener noreferrer'>
        <img src="../assets/email.png" alt="facebook-icon" height={20}/>
      </a>&nbsp;&nbsp;&nbsp;&nbsp;

      <a href='https://www.facebook.com/profile.php?id=61554743413903&mibextid=9R9pXO' target='_blank' rel='noopener noreferrer'>
        <img src="../assets/facebook.png" alt="facebook-icon" height={20}/>
      </a>&nbsp;&nbsp;&nbsp;&nbsp;

        <a href='https://twitter.com/_inittechnology' target='_blank' rel='noopener noreferrer'>
          <img src="../assets/twitter.png" alt="twitter-icon" height={20}/></a>
            &nbsp;&nbsp;&nbsp;&nbsp;

        <a href='https://www.instagram.com/init.technology' target='_blank' rel='noopener noreferrer'>
          <img src="../assets/instagram.png" alt="instagram-icon" height={20}/>
        </a>&nbsp;&nbsp;&nbsp;&nbsp;

        <a href='https://www.linkedin.com/company/init-technologyco' target='_blank' rel='noopener noreferrer'>
          <img src="../assets/linkedin.png" alt="linkedin-icon" height={20}/>
        </a><br />
        <p className='copyrights'>© Copyrights Init Technology | All Rights Reserved</p>
      </div>  
    </div>
  </div>
</div>

);
}
 
export default ComingSoon;
