import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ComingSoon from './component/ComingSoon/ComingSoon';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<ComingSoon/>} />        
        </Routes>
    </Router>
  </div>
  );
}

export default App;